<template>
  <div>
    <overlay-loader :loading="loading" />
    <hcc-table :title="$t('points.title')" :columns="columns" :rows="points"
      :pagination="currentPoints.length > 10" :rowsPerPage="10"
      @add="addNewItem" @edit="editItem" @delete="confirmDelete" />
    <edit-points-modal />
    <hcc-confirmation />
  </div>
</template>

<script>
import OverlayLoader from '../components/OverlayLoader.vue'
import HccTable from '../components/HccTable.vue'
import HccConfirmation from '../components/HccConfimation/index.vue'
import EditPointsModal from '@/components/EditPointsModal.vue'
import { mapState } from 'vuex'

export default {
  name: 'Points',
  components: { OverlayLoader, HccTable, HccConfirmation, EditPointsModal },
  data() {
    return {
      points: []
    }
  },
  computed: {
    ...mapState({
      currentPoints: state => state.points,
      loading: state => state.loading
    }),
    columns() {
      return [
        { label: this.$t('points.start'), field: 'start' },
        { label: this.$t('points.final'), field: 'end' },
        { label: this.$t('points.total'), field: 'total' }
      ]
    }
  },
  mounted() {
    this.$store.dispatch('getPoints')
  },
  methods: {
    addNewItem() {
      this.points.push({ start: 0, end: 1, total: 1 })
      this.$store.dispatch('savePoints', this.points)
    },
    editItem(row) {
      this.$modal.show('edit-points-modal', { row, points: this.points })
    },
    confirmDelete(item) {
      this.$modal.show('confirmation', {
        title: this.$t('points.delete'),
        description: this.$t('points.deleteConfirm'),
        variant: 'error',
        confirm: () => this.deleteItem(item)
      })
    },
    deleteItem(item) {
      // Deletes keys from VueGoodTable
      delete item.originalIndex
      delete item.vgt_id

      if (item.id) {
        this.$store.dispatch('deletePoints', item)
      } else {
        const index = this.points.indexOf(item)
        this.points.splice(index, 1)
      }
    }
  },
  watch: {
    currentPoints(newValue) {
      this.points = newValue.map(item => ({ ...item })).sort((a, b) => a.total - b.total)
    }
  }
}
</script>
