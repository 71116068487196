<template>
  <modal :name="name" :clickToClose="false" @before-open="beforeOpen"
    height="auto" width="400">
    <div class="confirmation-container" :class="params.variant">
      <hcc-confirmation-header :variant="params.variant"
        :title="params.title" />
      <div class="confirmation__description">{{ params.description }}</div>
    </div>
    <slot name="footer">
      <hcc-confirmation-footer :showCancel="showCancel"
        :variant="params.variant" :handleConfirm="handleConfirm"
        :handleCancel="handleCancel" />
    </slot>
  </modal>
</template>

<script>
import HccConfirmationHeader from './HccConfirmationHeader.vue'
import HccConfirmationFooter from './HccConfirmationFooter.vue'

export default {
  components: {
    HccConfirmationFooter,
    HccConfirmationHeader

  },
  props: {
    name: {
      type: String,
      default: 'confirmation'
    },
    confirmFunction: {
      type: Function
    },
    cancelFunction: {
      type: Function
    },
    showCancel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      params: {}
    }
  },
  methods: {
    beforeOpen(event) {
      this.params = { cancel: () => { }, confirm: () => { }, ...(event.params || {}) }
    },
    handleConfirm() {
      this.params.confirm()
      if (this.confirmFunction) {
        this.confirmFunction()
      }
      this.$modal.hide(this.name)
    },
    handleCancel() {
      this.params.cancel()
      if (this.cancelFunction) {
        this.confirmFunction()
      }
      this.$modal.hide(this.name)
    }
  }

}
</script>

<style scoped lang="scss">
@import "~styles/components/confirmation.scss";
</style>
