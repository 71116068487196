<template>
  <hcc-modal name="edit-points-modal" :title="$t('points.editPoints')"
    :scrollable="false" @before-open="beforeOpen" @opened="setData"
    @confirm="handleConfirm" :disableConfirmButton="!hasEdits">
    <div class="ticket-modal__item">
      <hcc-input type="number" :label="$t('points.start')" v-model="start"
        size="xlg" />
    </div>
    <div class="ticket-modal__item">
      <hcc-input type="number" :label="$t('points.final')" v-model="end"
        size="xlg" />
    </div>
    <div class="ticket-modal__item">
      <hcc-input type="number" :label="$t('points.total')" v-model="total"
        size="xlg" />
    </div>
  </hcc-modal>
</template>

<script>
import HccModal from '../components/HccModal/index.vue'
import HccInput from '../components/HccInput.vue'

export default {
  components: { HccModal, HccInput },
  data() {
    return {
      points: null,
      row: null,
      start: null,
      end: null,
      total: null
    }
  },
  computed: {
    hasEdits() {
      return this.start !== this.row?.start || this.end !== this.row?.end || this.total !== this.row?.total
    }
  },
  methods: {
    beforeOpen(event) {
      this.points = event.params.points
      this.row = event.params.row
    },
    setData() {
      this.start = this.row.start
      this.end = this.row.end
      this.total = this.row.total
    },
    handleConfirm() {
      if (this.hasEdits) {
        const editedPoint = {
          id: this.row.id,
          start: this.start,
          end: this.end,
          total: this.total
        }

        const index = this.points.findIndex((point) => point.id === editedPoint.id)

        const newPoints = this.points
        newPoints[index] = editedPoint

        this.$store.dispatch('savePoints', newPoints)
      }
    }

  }
}
</script>

<style lang="scss">
@import "~styles/views/tickets.scss";
</style>
