<template>
  <div class="confirmation__header">
    <div class="confirmation__header--title" :class="variant">
      {{ title }}
    </div>
    <hcc-button-icon v-if="variant === 'error'" round
      class="confirmation__header--icon" color="alert" unclickable>
      <delete-icon />
    </hcc-button-icon>
    <hcc-button-icon v-else-if="variant === 'info'" round
      class="confirmation__header--icon" color="primary" unclickable>
      <information-icon />
    </hcc-button-icon>
    <hcc-button-icon v-else round class="confirmation__header--icon"
      color="secondary" unclickable>
      <exclamation-icon />
    </hcc-button-icon>
  </div>
</template>

<script>
import HccButtonIcon from '../HccButtonIcon.vue'
import DeleteIcon from '@/assets/icons/delete-icon.svg'
import InformationIcon from '@/assets/icons/information.svg'
import ExclamationIcon from '@/assets/icons/exclamation.svg'

export default {
  components: {
    DeleteIcon,
    HccButtonIcon,
    InformationIcon,
    ExclamationIcon
  },
  props: {
    title: {
      type: String
    },
    variant: {
      type: String
    }
  }

}
</script>

<style scoped lang="scss">
@import "~styles/components/confirmation.scss";
</style>
